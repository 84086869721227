import React from "react";
import '../../components/Layout/layout.css';

const h3 = ({ children }) => {
  return (
    <h3 className="h1">{children}</h3>
  );
};

export default h3;
